import { Component } from "react";
import PropTypes from "prop-types"
import { btoa } from 'b64-lite'

export const findVariant = (shopify, variantId) => {
  let products = shopify.products
  let variant = {}
  
  products.map((p) => {
    return p.variants.map((v) => {
      if (btoa(v.storefrontId) === variantId) {
        variant = v 
        // set the shopifyId to the storefrontId for legacy support in Contentful+Shopify
        variant.shopifyId = v.storefrontId
        variant.storefrontId = v.storefrontId
        variant.qty = v.inventoryQuantity
        variant.product = {
          handle: p.handle,
          title: p.title,
          // set the shopifyId to the storefrontId for legacy support in Contentful+Shopify
          shopifyId: p.storefrontId,
          storefrontId: p.storefrontId,
        }
      }
      return v
    })
  })

  return variant
}

export default class Variant extends Component {
  render() {
    const variant = findVariant(this.props.shopify, this.props.variantId)
    return this.props.children(variant)
  }
}



// In case we want defaults later
// ------------------------------
// StaticProduct.defaultProps = {
//   lang: `en`,
//   meta: [],
//   description: ``,
// }

Variant.propTypes = {
  shopify: PropTypes.object.isRequired,
  variantId: PropTypes.string.isRequired
}