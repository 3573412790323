import React, { Component } from "react";
import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { h1, body } from 'components/Theme/Copy';
import { Container, Card } from 'components/Theme/Grid';

const HeroContainer = styled(Container)`
  flex-direction:column;
  height:30rem;
`;

const HeroCard = styled(Card)`
  background-color:${p => p.theme.cardBgColorDark};
  justify-content: center;
  align-items:center;
  
`;

const HeroCopy = styled.div`
  
`;

const HeroHeadline = styled(h1)`
  margin-bottom:2.5rem;
  color:#fff;
  text-align: center;
  ${media.greaterThan("tablet")`
    line-height:6.4rem;
  `};
  
`;

const HeroSubheadline = styled(body)`
  color:#fff;

  ${media.lessThan("tablet")`
    padding:0 3rem;
    text-align:center;
  `};
`;


class Hero extends Component {

  render() {
    const { heroHeadline, heroSubheadline } = this.props.data;
    return (
      <HeroContainer> 
        <HeroCard neumorphic>
          <HeroCopy>
            <HeroHeadline>{heroHeadline}</HeroHeadline>
            <HeroSubheadline>{heroSubheadline}</HeroSubheadline>
          </HeroCopy>
        </HeroCard>
      </HeroContainer>
    )
  }
}



export default Hero
