import React, { Component } from "react";
import { findVariant } from 'components/Shopify/Variant';

import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { Container, Card  } from 'components/Theme/Grid';
import { body } from 'components/Theme/Copy';

import { GatsbyImage } from "gatsby-plugin-image"

import { Link } from 'gatsby';
import Price from 'components/Theme/Price';
import Button from 'components/Theme/Button'


const ListContainer = styled(Container)`
  background-color:${p => p.theme.white};

  ${media.lessThan("tablet")`
    flex-direction:column;
  `};
  
`;

const Product = styled(Card)`
  justify-content: center;
  align-items:center;
  padding:4rem;
  flex-basis: calc(33% - 4.5rem) !important;
  ${media.lessThan("tablet")`
    flex-basis: 100%;
    padding:4rem 0;
  `};
`;

const ProductLink = styled(Link)`
  display:flex;
  justify-content: center;
  align-items:center;
  flex-direction: column;
`;

const AssetWrapper = styled.div`
  height:40rem;
  display:flex;
  justify-content: center;
  align-items: center;
`;

const Asset = styled(GatsbyImage)`
  width: 40rem;
`;

const Heading = styled(body)`
  margin-bottom:0.5rem;
  text-align: center;
  color:${p => p.theme.black};
`;

const SalesPrice = styled(Price)`
  justify-content:center;
  margin:0 0 2rem 0;
  color:${p => p.theme.black};
`;

const BuyNowButton = styled(Button)`
  font-size:1.4rem;
  padding:1.5rem 3rem;
`;

class List extends Component {

  findShopifyVariant = (variantId) => {
    return findVariant(this.props.shopify, variantId);
  }

  render() {
    const products = this.props.data;

    return ( 
      <ListContainer neumorphic>
        {products.map((product, i) => {
          return (
            <Product key={`${product.handle}_${i}`}  size={4} sm={1}>
              <ProductLink to={"/products/" + product.handle}>
                <AssetWrapper>
                  <Asset image={product.variants[0].images[0].gatsbyImageData} />
                </AssetWrapper>
                <Heading>{ product.title }</Heading>
                {/* <SalesPrice price={this.findShopifyVariant(product.variants[0].shopifyId).price}
                            compareAtPrice={this.findShopifyVariant(product.variants[0].shopifyId).compareAtPrice} /> */}


                <SalesPrice price={(parseFloat(this.findShopifyVariant(product.variants[0].shopifyId).price))} />

                <BuyNowButton to={'/products/' + product.handle + '?variant=' + product.variants[0].shopifyId}>BUY NOW</BuyNowButton>
              </ProductLink>
            </Product>
          )
        })}
      </ListContainer>
    )
  }
}

export default List