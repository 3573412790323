import styled, { css } from 'styled-components';
import { media } from 'components/Theme/Styles';

const height = '60rem';
const gutter = 1.5;

export const neumorphic = css`
  box-shadow:0 0 1rem 0.2rem rgba(0,0,0,0.15);
`;

export const colSize = (size) => {
  if (size <= 0) { size = 1 }

  let width = 100/size;
  let gutterSize = (size - 1 || 1) * gutter;
  return flexBasis(width, gutterSize)
}

export const flexBasis = (percent, gutterSize) => {
  let _gutter = gutter

  if (gutterSize) {
    _gutter = gutterSize
  }
  
  return `flex-basis: calc(${percent}% - ${_gutter}rem);`
}

export const Container = styled.div`
  ${p => p.neumorphic ? neumorphic : null };
  ${p => p.fixedHeight ? `height:`+height : null };
  ${p => p.gutterBottom ? `margin-bottom:12rem;` : null };
  ${p => p.gutterTop ? `margin-top:12rem;` : null };
  ${p => p.gutterBottomSmall ? `margin-bottom:6rem;` : null };

  display:flex;
  flex-direction:row;
  flex-wrap:wrap;

  ${media.lessThan("tablet")`
    flex-direction:column;
  `};
`;

export const CardStyle = css`
  ${p => p.neumorphic ? neumorphic : null }
  ${p => p.stretch ? `height:`+height : null }
  ${p => p.noGutter ? 'margin-bottom:0 !important;margin-left:0 !important;' : null};
  flex:1 1 auto;
  display:flex;

  ${ p => p.size && colSize(p.size) }
  ${ p => p.size > 1 ? `margin-right:${gutter}rem;` : null };
  margin-bottom:${gutter}rem;

  ${media.greaterThan("tablet")`
    &:nth-child(${ p => p.size && p.size}n) {
      margin-right:0;
    }
  `};

  /* callaspe to 2 columns */
  ${media.lessThan("tablet")`
    ${p => p.sm && colSize(p.sm)}

    &:nth-child(-n+${p => p.sm && p.sm}) {
      /* ${p => p.sm > 1 ? 'margin-bottom:0' : null }; */
    }

    &:nth-child(${p => p.sm && p.sm}n) {
      margin-right:0;
    }
  `};
`;


export const Card = styled.div`
  ${CardStyle}
`;




