import React, { Component } from "react";
import { graphql } from 'gatsby'
import _ from 'lodash';

import Layout from "components/layout";
import SEO from "components/seo";
import Hero from 'components/Page/Accessories/Hero';
import List from 'components/Page/Accessories/List';

class Accessories extends Component {
  render() {
    const contentfulAccessories = this.props.data.contentfulAccessoriesList;
    const heroData = _.pickBy(contentfulAccessories, (v, k) => { return /^hero/.test(k) })

    const productsData = this.props.data.allContentfulProduct.nodes
    const shopify = this.props.pageContext.shopify

    return ( 
      <Layout >
        <SEO title = 'Accessories' />
        <Hero data = { heroData } />
        <List data = { productsData } shopify={ shopify } />
      </Layout >
    )
  }
}

export default Accessories

// language=GraphQL
export const pageQuery = graphql `
  query AccessoriesQuery {
    contentfulAccessoriesList {
      heroHeadline
      heroSubheadline
      bodyImage {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }

    allContentfulProduct(filter: { category: {eq: "Accessories"}}) { 
      nodes {
        id
        handle
        category
        title
        variants {
          shopifyId
          images {
            gatsbyImageData(width: 400)
          }
        }
      }
    }
  }

  
`;