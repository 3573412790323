import React, { Component } from "react";
import styled from 'styled-components';
import { media } from 'components/Theme/Styles';

const PriceContainer = styled.div`
  display:flex;
  margin:1rem 0 2rem 0;
  font-size:1.7rem;
  flex-direction:row-reverse;
`;

const ComparePrice = styled.div`
  color:#999;
  text-decoration:line-through;
  font-weight:300;
  margin-left:1rem;
  ${media.lessThan("tablet")`
    font-size:1.7rem;
  `}
`;

const ProductPrice = styled.div`
  font-weight:400;
  ${media.lessThan("tablet")`
    font-size:1.7rem;
  `}
`;

class Price extends Component {

  render() {
    return (
      <PriceContainer className={this.props.className}>
        {this.props.compareAtPrice > 0 && 
          <ComparePrice>${this.props.compareAtPrice}</ComparePrice>
        }
        {this.props.price > 0 && 
          <ProductPrice>${this.props.price}</ProductPrice>
        }
      </PriceContainer>
    )
  }
}

export default Price
